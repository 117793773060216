import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {pickImageStyle} from "@/utils/transforms";
import parse from 'html-react-parser';
import Image from 'next/image';
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-disableElevation": {
      margin: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        margin: '.25rem 1rem',
      },
    },
  },
  bodyStyles: {
    fontSize: '18px',
  },
  waveTop: {
    width: '100%',
    overflow: 'hidden',
    lineHeight: 0,
  },
  waveBottom: {
    transform: 'rotate(180deg)',
    width: '100%',
    overflow: 'hidden',
    lineHeight: 0,
  },
  titleStyles: {
    fontWeight: 700,
    lineHeight: '1.5',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  blocksContainer: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& .imgContainer': {
        justifyContent: 'center',
        marginBottom: '1rem',
      },
    },
  },
  '.cta-img-right, .cta-img-left': {
    [theme.breakpoints.up('md')]: {
      '& .imgContainer': {
        maxWidth: '55%',
      }
    }
  },
  textGridStyles: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '45%'
    },

    "& .MuiBox-root, & .MuiGrid-root": {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  },
  imgLeft: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
    },
  },
  imgRight: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-around',
      '& .imgContainer': {
        justifyContent: 'flex-end',
      },
    },
  },
  imgStyles: {
    width: '100%',
    maxHeight: '400px',
    height: '100%',
    objectFit: 'cover',
  },
  buttonStyles: {
    marginBottom: 10,
  }
}),
);

const BlockCta = (props) => {
  let { node, block } = props;
  node = (!node && block) ? block : node;

  let { title, body, imgAlt, imgTitle, imgWidth, imgHeight, imageOrig, image_styles,
    ctaLinks, btnOpts, bgColor, position, wavesStyle } = node;
  const classes = useStyles(node);

  let waveClr = '#00B3DC';
  if (wavesStyle?.second?.length > 0) {
    waveClr = wavesStyle.second == 'dark blue' ? '#02606E' : (wavesStyle.second == 'light blue' ? '#00B3DC' : '#00B3DC');
  }

  let getImageStyle = pickImageStyle({ imgAlt, imgTitle, imgWidth, imgHeight, imageOrig, image_styles, title: 'Media' },
      'max_510_height', false);
  ({ imgAlt, imgTitle, imgWidth, imgHeight, imageOrig } = getImageStyle);

  let btnAlign = btnOpts?.first.toLowerCase() == 'right' ? 'flex-end' : (btnOpts?.first.toLowerCase() == 'left' ? 'flex-start' : 'center');
  position = position || 'top';

  /*
  @todo:
    1. account for "btnOpts.second" value - sets button font to special
    2. account for "bgColor" value - sets bg of the whole card
    3. do we need AlignFull option??
  */
  return (
      <div className={classes.root}>
        <Box py={4} bgcolor={bgColor + `.contrastText`} className={`cta-img-${position?.toLowerCase()}`}>
          {wavesStyle?.first?.length > 0 && ['top', 'both'].includes(wavesStyle.first) &&
          <div className={classes.waveTop}>
            <svg viewBox="0 0 1793 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill={waveClr} fillRule="evenodd" clipRule="evenodd" d="M1.92589 169.143C1.28174 169.143 0.637572 169.143 6.10278e-05 169.116L1.61829e-05 0.00038147L1793 6.79716e-05L1793 169.116C1756.24 168.237 1728.96 147.319 1728.96 147.319C1728.96 147.319 1664.93 98.2124 1600.89 147.319C1536.86 196.425 1472.82 147.319 1472.82 147.319C1472.82 147.319 1408.78 98.2125 1344.75 147.319C1280.72 196.425 1216.68 147.319 1216.68 147.319C1216.68 147.319 1152.64 98.2125 1088.61 147.319C1024.57 196.425 960.533 147.319 960.533 147.319C960.533 147.319 896.5 98.2126 832.463 147.319C768.427 196.425 704.393 147.319 704.393 147.319C704.393 147.319 640.357 98.2126 576.32 147.319C512.283 196.425 448.25 147.319 448.25 147.319C448.25 147.319 384.213 98.2127 320.177 147.319C256.14 196.425 192.107 147.319 192.107 147.319C192.107 147.319 128.07 98.2127 64.0334 147.319C42.5241 163.819 21.0114 169.231 1.92589 169.143Z" />
            </svg>
          </div>
          }
          <Grid container alignItems='center'
                className={`${position?.toLowerCase() == 'right' ? classes.imgRight : classes.imgLeft} ${classes.blocksContainer}`}>
            {imageOrig &&
            <Grid md={position?.toLowerCase() == 'top' ? 12 : 6} item className={'imgContainer'}>
              <Image layout="responsive" sizes="50vw" className={classes.imgStyles} src={imageOrig} alt={imgAlt}
                     title={imgTitle} width={imgWidth} height={imgHeight} />
            </Grid>
            }

            <Grid md className={position?.toLowerCase() != 'top' ? classes.textGridStyles : ''} item>
              <Box className={classes.titleBox} px={2} py={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                {title &&
                <Typography variant='h2' className={classes.titleStyles}>{title}</Typography>
                }
                {body &&
                <Typography component={`div`} className={classes.bodyStyles}
                            dangerouslySetInnerHTML={{ __html: body }}></Typography>
                }
                {ctaLinks?.length > 0 && position?.toLowerCase() != 'bottom' &&
                <Grid container justifyContent={btnAlign} spacing={4}>
                  {ctaLinks?.map((btn, index) => (
                      <Grid item key={`btn-i-${index}`}>
                        {btnOpts.second &&
                        <Button disableElevation variant="contained" className={`${classes.buttonStyles} serif`} color="primary"
                                href={btn.second}>{parse(btn.first)}</Button>
                        }
                        {!btnOpts.second &&

                        <Button disableElevation className={classes.buttonStyles} variant="contained" color="primary"
                                href={btn.second}>{parse(btn.first)}</Button>
                        }

                      </Grid>
                  ))}
                </Grid>
                }
              </Box>
            </Grid>
          </Grid>
          {wavesStyle?.first?.length > 0 && ['bottom', 'both'].includes(wavesStyle.first) &&
          <div className={classes.waveBottom}>
            <svg viewBox="0 0 1793 170" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill={waveClr} fillRule="evenodd" clipRule="evenodd" d="M1.92589 169.143C1.28174 169.143 0.637572 169.143 6.10278e-05 169.116L1.61829e-05 0.00038147L1793 6.79716e-05L1793 169.116C1756.24 168.237 1728.96 147.319 1728.96 147.319C1728.96 147.319 1664.93 98.2124 1600.89 147.319C1536.86 196.425 1472.82 147.319 1472.82 147.319C1472.82 147.319 1408.78 98.2125 1344.75 147.319C1280.72 196.425 1216.68 147.319 1216.68 147.319C1216.68 147.319 1152.64 98.2125 1088.61 147.319C1024.57 196.425 960.533 147.319 960.533 147.319C960.533 147.319 896.5 98.2126 832.463 147.319C768.427 196.425 704.393 147.319 704.393 147.319C704.393 147.319 640.357 98.2126 576.32 147.319C512.283 196.425 448.25 147.319 448.25 147.319C448.25 147.319 384.213 98.2127 320.177 147.319C256.14 196.425 192.107 147.319 192.107 147.319C192.107 147.319 128.07 98.2127 64.0334 147.319C42.5241 163.819 21.0114 169.231 1.92589 169.143Z" />
            </svg>
          </div>
          }
        </Box></div>
  );
};

export default BlockCta;
